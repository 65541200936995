.home-slide {
	width: 100%;
	padding-top: 33%;
	background-size: 100%;
	background-position: center; 
}
	
@media (min-width: 992px) {
	.home-slide {
		width: 100%;
		padding-top: 0;
		height: 200px;
		background-size: 100%;
		background-position: center; 
	}
}

.home section {
    overflow: hidden;
}

.home section .text {
	text-align: justify;
    font-size: 13px;
}

@media (min-width: 992px)
.home .about .section-content {
    min-height: 520px;
}

@media (min-width: 768px)
.home .about .section-content {
    min-height: inherit;
}

.gallery .img-fluid {
	max-height: 325px;
}
	
@media (min-width: 768px and max-width: 1199px) {
	.gallery .img-fluid {
		max-height: 215px;
	}
}

@media (min-width: 1200px) {
	.gallery .img-fluid {
		max-height: 228px;
	}
}

.gallery .content {
	text-align: center;
}

.testimonials .quote {
    padding-left: 15px;
    border-left: 4px solid #e6e5e5;
}

.trainer-name {
	text-align: center!important;
	height: 3.5rem;
}

.home .testimonials .svg-inline--fa {
    color: #6091ba;
    margin-right: 5px;
    font-size: 18px;
    overflow: visible;
    width: 1em;
}

img.emoji {
    display: inline !important;
    border: none !important;
    box-shadow: none !important;
    height: 1em !important;
    width: 1em !important;
    margin: 0 .07em !important;
    vertical-align: -0.1em !important;
    background: none !important;
    padding: 0 !important;
}

.body h3 {
	color: #3697d9;
	font-size: 18px;
}

@media (max-width: 767px) {
	#facebook_slider_button {
    	top: 150px;
    	position: absolute;
	}
}

@media (min-width: 992px) {
	.modal-dialog-popup {
    	max-width: 800px;
    	margin: 1.75rem auto;
	}
}

.fadeSlow {
    transition: opacity 1s linear;
}

.home_banner {
	max-height: 400px;
	margin: auto;
}

.owl-banners .owl-item img {
    width: auto!important;
}